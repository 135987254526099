<template>
  <section>
    <SearchableTable
      :items="items"
      :commands="commands"
      :fields="fields"
      :showSelected="true"
      :deepSearch="false"
      @select="onSelect"
      @unselect="onUnselect"
      @command="onCommand"
    />
  </section>
</template>

<script>
import SearchableTable from "@/components/searchable-table.vue";
export default {
  name: "UserGroupTable",
  components: {
    SearchableTable
  },
  props: {
    groups: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      items: null,
      fields: [
        {
          name: "name",
          title: "name"
        },
        {
          name: "description",
          title: "description"
        }
      ],
      commands: [
        {
          name: "create",
          title: "new",
          icon: "fa fa-plus",
          enabled: () => this.$can("manage", "GrupoDeUsuariosCadastro")
        }
      ]
    };
  },
  methods: {
    onCommand(command) {
      if (command.name == "create") {
        this.$emit("create");
      }
    },
    onSelect(item) {
      this.$emit("select", item);
    },
    onUnselect(item) {
      this.$emit("unselect");
    },
    initData() {
      this.$set(this, "items", this.groups);
    }
  },
  created() {
    this.initData();
  }
};
</script>

<style scoped>
section {
  padding-top: 10px;
}
</style>
